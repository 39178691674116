import React, { useEffect, useState } from "react";
import { clientSupabase } from "../../config/supabas-client";
import ActivityCard from "../../components/activity-card";

export default function HomePage() {
  const [activity, setActivity] = useState<any>([]);
  const getActivity = async () => {
    await clientSupabase
      .from("activity")
      .select("*")
      .then((e: any) => {
        console.log(e?.data);
        setActivity(e?.data);
      });
  };

  useEffect(() => {
    getActivity();
  }, []);

  const handleRedirect = () => {
    const phoneNumber = "+50671770168"; // Replace with your WhatsApp Business phone number
    const message = "Hello!"; // You can customize the initial message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(url, "_blank");
  };
  return (
    <div className="h-screen py-20 px-10">
      <div className="mb-4">
        <div className="font-bold text-7xl text-[#1a7e84] max-md:text-3xl">
          PamiPami
        </div>
        <div className="font-normal text-2xl text-[#b5b5b5]">
          Concierge Services
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-4">
        {activity?.map((el: any) => {
          return (
            <div
              onClick={handleRedirect}
              className="cursor-pointer w-3/6 rounded-xl max-md:w-full h-12 bg-[#1a7e84] text-white flex justify-center items-center"
            >
              {el?.activity_name}
            </div>
          );
        })}
      </div>
    </div>
  );
}
